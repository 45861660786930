.chat-window-agent-container {
  margin: 0px 0px 0px var(--sidebar-agent-container-width);
  /* background: #edebebd6; */
  width: 100%;
  height: 100vh;
  padding: 10px 30px 17px 30px;
  box-sizing: border-box;
  box-shadow: 0px 67.114px 109.061px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.chat-window-agent-container-header {
  width: 100%;
  background-color: rgb(29, 29, 29);
  color: white;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.chat-window-agent-container-header button {
  background: #474747;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  color: wheat;
}

.chat-window-agent-container-body {
  flex: 1 1;
  background-color: rgb(29, 29, 29);
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}

.chat-window-agent-container-body-send-message {
  width: 100%;
  display: flex;
  gap: 10px;
}

.chat-window-agent-container-body-send-message input {
  flex: 1 1;
  background: #404041;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  border: none;
  font-size: 16px;
}

.chat-window-agent-container-body-send-message button {
  background: #404041;
  border: none;
  width: 50px;
  font-size: 28px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
}

.chat-window-agent-container-body-send-message button :disabled {
  background: #747475;
  border: none;
  width: 50px;
  font-size: 28px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
}

.chat-window-agent-container-body-send-message label {
  background: #404041;
  border: none;
  width: 50px;
  font-size: 28px;
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
}

.chat-window-agent-container-body-chat {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.chat-window-agent-container-body-chat .message-container {
  width: 100%;
  display: flex;
}

.chat-window-agent-container-body-chat .message {
  max-width: 400px;
  padding: 10px 33px;
  /* background: #5b5b5b; */
  color: white;
  border-radius: 6px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  text-wrap: wrap;
  word-break: break-word;
}

.chat-window-agent-container-body-chat .left {
  justify-content: flex-start;
}

.chat-window-agent-container-body-chat .center {
  justify-content: center;
}

.chat-window-agent-container-body-chat .right {
  justify-content: flex-end;
}

.chat-window-agent-container-body-chat .from {
  background-color: #2b2b2b;
}

.chat-window-agent-container-body-chat .to {
  background-color: #2b2b2b;
}

.select-conv-to-start {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feedback-container textarea {
  width: 400px;
  height: 100px;
  resize: none;
  border: none;
  outline: none;
  background-color: #242424;
  color: white;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
}

.fb-submit-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.feedback-container button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #066706;
  padding: 10px 20px;
  width: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
}
.internal-message {
  justify-content: center;
  color: #797676;
  background: #8080801f;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  padding: 10px 23px;
  max-width: 500px;
  border-radius: 10px;
  /* margin-bottom: 7px; */
}

.chat-agent-image-preview {
  width: 300px;
  height: 200px;
  border-radius: 6px;
  object-fit: cover;
}

@media all and (max-width: 720px) {
  .internal-message {
    justify-content: center;
    color: #797676;
    background: #8080801f;
    width: -moz-fit-content;
    width: 200px;
    text-align: center;
    padding: 10px 23px;
    max-width: 500px;
    border-radius: 10px;
    /* margin-bottom: 7px; */
    word-break: break-word;
  }
}

@media all and (max-width: 660px) {
  .chat-window-agent-container {
    margin: 0px 0px 0px 0px;
    padding: 17px 30px 17px 30px;
  }
  .internal-message {
    width: 60vw;
  }
}

@media all and (max-width: 650px) {
  .chat-agent-image-preview {
    width: 244px;
    height: 157px;
    border-radius: 6px;
    object-fit: cover;
  }
}

@media all and (max-width: 502px) {
  .chat-window-agent-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 17px 0px;
  }
}
