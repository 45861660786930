.chat-agent-main-container {
  background: #212020;
  display: flex;
}

@media all and (max-width: 660px) {
  .chat-agent-main-container {
    flex-direction: column;
  }
  .sidebar-agent-container {
    width: 100vw;
    height: 200px;
    background: rgb(29, 29, 29);
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px 10px 0px 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* position: fixed; */
  }
}
