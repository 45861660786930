/* Toast.css */
.toast {
  position: fixed;
  top: 80px;
  right: 0px;
  transform: translateX(-10%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  /* transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); */
  /* z-index: 0; */
}
@keyframes mymove {
  from {
    right: 0px;
  }
  to {
    right: 50px;
  }
}
.toast.show {
  opacity: 1;
  z-index: 10000;
  animation: mymove 0.9s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.075, 0.82);
}

.toast-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.successToast {
  background-color: #4bb543;
}

.errorToast {
  background-color: #d03636;
}

.warningToast {
  background-color: rgb(244, 154, 80);
}
