.image-upload-modal-main-container {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 13px;
  background-color: #2f2f2f7d;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-upload-modal-main-container .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-upload-modal-main-container .button-set button {
  margin-top: 12px;
  padding: 9px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #494949;
  color: white;
  font-size: 15px;
}

.custum-file-upload {
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #cacaca;
  background-color: rgb(69 69 69 / 70%);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
}

.custom-file-upload-preview {
  width: 300px;
  height: 200px;
  object-fit: cover;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
  border: 2px dashed #cacaca;
}

.custom-file-upload-preview img {
  border-radius: 10px;
}

.custum-file-upload .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon svg {
  height: 80px;
  fill: rgb(189 191 193);
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
  color: rgb(239 239 239);
}

.custum-file-upload input {
  display: none;
}
