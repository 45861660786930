:root {
  /* Label */
  --field-label-padding: 6px;
  --field-label-color: #303972;
  --field-label-font-size: var(--font-18-px);
  --field-label-font-weight: 600;
  /* Field: Common */
  --custom-input-border-radius: 5px;
  --custom-input-border: 1px solid #c1bbeb;
  --custom-input-focus-border: 1px solid black;
  --custom-input-background: var(--white-100, #fff);
  /* Label: Text */
  --custom-text-indside-padding: 8px 8px 8px 8px;
}

.custom-text-label {
  color: var(--field-label-color);
  font-family: Poppins;
  font-size: var(--field-label-font-size);
  font-style: normal;
  font-weight: var(--field-label-font-weight);
  line-height: normal;
}

.custom-text-field {
  width: 100%;
  box-sizing: border-box;
  padding: var(--custom-text-indside-padding);
  border-radius: var(--custom-input-border-radius);
  /* border: var(--custom-input-border); */
  /* background: var(--custom-input-background); */
  outline: none;
  /* color: rgb(189, 184, 184) !important; */

  background: #232323 !important;
  border: 1px solid rgb(73 71 71);
  color: var(--disabled-text-color, #ccc);
}

.custom-text-field:focus {
  border: var(--custom-input-focus-border);
}

.custom-text-field:disabled {
  border: 1px solid rgb(73 71 71);
  background: #292929 !important;
  color: rgb(189, 184, 184);
  /* cursor: not-allowed; */
  outline: none;
}

.custom-input-container {
  display: flex;
  flex-direction: column;
  gap: var(--field-label-padding);
}

.password-open-eye {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: gray;
  font-size: 15px;
  cursor: pointer;
}


@media all and (max-width: 550px) {
  .custom-text-label {
    font-size: var(--mobile-font-14-px);
  }
}

.select-field {
  appearance: none;
  background: #232323;
  background-image: url(./arrow.png);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;

  width: 100%;
  box-sizing: border-box;
  padding: var(--custom-text-indside-padding);
  border-radius: var(--custom-input-border-radius);
  outline: none;

  border: 1px solid rgb(73 71 71);
  color: var(--disabled-text-color, #ccc);
}

/* .select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  color: #938f8f;
  font-size: 10px;
  top: 11px;
  right: 15px;
  position: absolute;
} */
