.admin-all-chapters-container {
  width: 100%;
  /* border: 1px solid #80808030; */
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 14px;
  background: #1d1d1d;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 70px); */
  gap: 30px;
}

.admin-agent-management-heading {
  color: #cdc6b7;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.back-button-manage-agent {
  color: #cdc6b7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-all-agents-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-new-agent-button {
  border: none;
  background-color: #2f8af5;
  color: white;
  font-weight: 400;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* margin-top: 10px; */
  min-width: 100px;
}

@media all and (max-width: 1100px) {
  .admin-all-chapters-container {
    width: 100%;
    /* border: 1px solid #80808030; */
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 14px;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 70px); */
    gap: 30px;
  }
  .add-new-agent-button {
    margin-top: 0px;
  }
}
@media all and (max-width: 600px) {
  .admin-all-chapters-container {
    width: 100%;
    /* border: 1px solid #80808030; */
    height: 100%;
    padding: 0px;
    box-sizing: border-box;
    border-radius: 0px;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - 70px); */
    gap: 20px;
  }
}
