.sidebar-agent-container {
  width: var(--sidebar-agent-container-width);
  height: 100vh;
  background: rgb(29, 29, 29);
  box-sizing: border-box;
  overflow: hidden;
  padding: 20px 10px 0px 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  gap: 10px;
}

.user-image-sidebar-agent {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar-agent-container .user-section {
  width: 100%;
  display: flex;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  /* padding-bottom: 16px; */
  padding: 10px;
  /* border-bottom: 1px solid #4b4b4b; */
  background: #2727279c;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.sidebar-agent-container .convo-section {
  background: #2727279c;
  width: 100%;
  flex: 1;
  /* margin-top: 20px; */
  padding: 5px;
  box-sizing: border-box;
  overflow: auto;
  border-radius: 8px;
  color: white;
}

.sidebar-agent-container .user-section .logout {
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #a34d4d;
}

.agent-main-sidebar-container {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
}

.agent-main-sidebar-container .single-convo {
  padding: 20px;
  background: rgba(53, 53, 53, 0.842);
  border-radius: 8px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  /* overflow: hidden; */
  word-wrap: break-word;
}

.agent-main-sidebar-container .single-convo:hover {
  background: rgb(24, 24, 24);
}

.convo-done {
  border-right: 4px solid green;
}

.convo-pending {
  border-right: 4px solid orange;
}

.active-conv {
  background-color: rgb(78, 78, 78) !important;
}

@media all and (max-width: 660px) {
  .sidebar-agent-container {
    width: 100vw;
    height: 236px;
    overflow: hidden;
    padding: 20px 10px 0px 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: sticky;
    background: transparent;
  }
  .agent-main-sidebar-container {
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }
  .agent-main-sidebar-container .single-convo {
    padding: 15px;
    background: rgba(53, 53, 53, 0.842);
    border-radius: 8px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 10ch;
    width: -moz-fit-content;
    width: 15ch;
  }
  /* .agent-main-sidebar-container .single-convo {
    padding: 23px 69px;
    background: rgba(53, 53, 53, 0.842);
    border-radius: 8px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    overflow: hidden;
    overflow: hidden;
    width: -moz-fit-content;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 15ch;
  } */
}

.agent-management-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  color: #c1c1d5;
  text-decoration: none;
}

@media all and (max-width: 660px) {
  .hide-on-mobile {
    display: none;
  }

  .change-height-on-admin {
    height: 136px;
  }
}
