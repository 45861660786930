@import "./theme/theme.css";

body {
  margin: 0;
  background-color: rgb(29, 29, 29);
}

* {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* *,
*::before,
*::after {
  box-sizing: border-box;
} */

.home-top-space {
  margin-top: 10vh;
}

.form-error-message {
  font-size: 14px;
  color: red;
  font-weight: 500;
}

.dashboard-form-delete-button {
  border: none;
  background-color: transparent;
}

@media all and (max-width: 550px) {
  .home-top-space {
    margin-top: 5vh;
  }
  .db-add-hide-on-mobile {
    display: none;
  }
}

@media all and (max-width: 926px) {
  .emtpty-field-db-form {
    display: none;
  }
  * {
    cursor: default !important;
  }
}

.infinite-loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px 150px 0px;
}

/* Hide scrollbar for all elements */
* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  display: none; /* Webkit browsers */
}

/* For WebKit browsers */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* For Firefox */
* {
  scrollbar-color: #00000061 #0000000d;
  scrollbar-width: thin;
}
