.update-agent-modal {
  background: #272727;
  width: 600px;
  padding: 24px 23px;
  box-sizing: border-box;
  border-radius: 20px;
}

.update-agent-body {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.update-agent-line {
  display: flex;
  width: 100%;
  gap: 10px;
}

@media all and (max-width: 700px) {
  .update-agent-line {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-direction: column;
  }
  .mobile-space-add {
    margin-bottom: 22px;
  }
  .update-agent-modal {
    background: #272727;
    width: 90vw;
    padding: 24px 23px;
    box-sizing: border-box;
    border-radius: 20px;
    height: 90vh;
    overflow: auto;
    position: fixed;
  }
}

@media all and (max-width: 600px) {
  .admin-all-agents-header {
    padding: 9px 30px;
  }
  .update-agent-modal {
    background: #272727;
    width: 100vw;
    padding: 24px 23px;
    box-sizing: border-box;
    border-radius: 0px;
    height: 100vh;
    overflow: auto;
    position: fixed;
  }
}
