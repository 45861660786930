.add-agent-modal-container {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: #000000a6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-agent-modal {
  background: #272727;
  width: 300px;
  padding: 24px 23px;
  box-sizing: border-box;
  border-radius: 20px;
}

.add-agent-body {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.add-agent-header {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-agent-close-button {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: antiquewhite;
  border: none;
  cursor: pointer;
}

.add-agent-heading {
  font-size: 18px;
  color: antiquewhite;
}

.divider-add-agent {
  height: 1px;
  width: 100%;
  background-color: #fdfdfd36;
  margin: 19px 0px;
}
