:root {
  --sidebar-agent-container-width: 300px;
}

@media all and (max-width: 884px) {
  :root {
    --sidebar-agent-container-width: 200px;
  }
}


@media screen and (min-width: 0px) and (max-width: 550px) {
  :root {
  }
}

@media only screen and (min-width: 551px) and (max-width: 768px) {
  :root {
  }
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
  :root {
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  :root {
  }
}

@media screen and (min-width: 1920px) {
  :root {
  }
}
