.card {
  width: 354px;
  height: 257px;
  background-color: rgb(41 40 40 / 52%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  gap: 22px;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
}

.card-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000075;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

#cookieSvg {
  /* width: 118px; */
  font-size: 52px;
  color: gray;
}
.cookieHeading {
  font-size: 25px;
  font-weight: 600;
  color: rgb(26, 26, 26);
  margin: 0px;
  color: white;
}
.cookieDescription {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: rgb(99, 99, 99);
  margin: 0px;
  color: white;
}

.cookieDescription a {
  --tw-text-opacity: 1;
  color: rgb(59 130 246);
}

.cookieDescription a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.acceptButton {
  width: 107px;
  height: 35px;
  background-color: green;
  /* transition-duration: 0.2s; */
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  border-radius: 7px;
  /* box-shadow: 0 4px 6px -1px #977ef3, 0 2px 4px -1px #977ef3; */
  /* transition: all 0.6s ease; */
}

.declineButton {
  width: 107px;
  height: 35px;
  background-color: rgb(204, 132, 64);
  /* transition-duration: 0.2s; */
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  border-radius: 7px;
  /* box-shadow: 0 4px 6px -1px #977ef3, 0 2px 4px -1px #977ef3; */
  /* transition: all 0.6s ease; */
}
