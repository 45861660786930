.forgot-pw-send-email-button {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  border: none;
  border-radius: 7px;
  background: #2f8af5;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.forgot-pw-send-email-button:disabled {
  background-color: #2c4a6d;
}

.email-send-container-forgot {
  display: flex;
  gap: 9px;
  align-items: flex-end;
  flex-wrap: wrap;
}
/* 
@media all and (max-width: 550px) {
  .email-send-container-forgot {
    display: flex;
    gap: 9px;
    align-items: flex-end;
    flex-direction: column;
  }
} */
