.card-table-module {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 30px;
  margin-top: -30px;
}

.card-table-container {
  overflow-x: auto;
}

.card-th-table-module {
  color: var(--table-header-color);
  font-family: Poppins;
  font-size: var(--medium-font-size);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card-tr-table-module:nth-of-type(2n-1) .card-td-table-module:first-child {
  box-shadow: inset 41px 0px 0px -37px #233239;
  z-index: 100000;
}

.card-caption-table-module,
.card-th-table-module,
.card-tr-table-module {
  padding: 20px;
}

.card-td-table-module {
  padding: 32px 20px;
  /* border-bottom: 1px solid #d5d5d5; */
  /* border-top: 1px solid #d5d5d5; */
  background: #242424;
}

.card-td-table-module:first-child {
  /* border-left: 1px solid #d5d5d5; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.card-tr-table-module:hover .card-td-table-module:first-child {
  /* box-shadow: inset 6px 0px 0px 0px #2e2559d9; */
}

.card-td-table-module:last-child {
  /* border-right: 1px solid #d5d5d5; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-dynamic-table-tr {
  color: var(--dynamic-table-row);
  font-family: Poppins;
  font-size: var(--medium-font-size);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.card-dynamic-table-tr:hover .card-td-table-module {
  background-color: #333333;
}

.card-dynamic-table-tbody
  .card-tr-table-module
  .card-tr-table-module:last-child {
  border-bottom: 0px;
}

.card-th-table-module {
  color: white;
  background-color: rgb(35 50 57);
}
.card-th-table-module:first-child {
  border-top-left-radius: 10px;
}

.card-th-table-module:last-child {
  border-top-right-radius: 10px;
}

@media (max-width: 1020px) {
  .card-th-table-module {
    display: none;
  }
  .card-dynamic-table-tbody {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .card-td-table-module {
    display: grid;
    grid-template-columns: 1fr 3fr;
    text-align: left !important;
    gap: 16px;
  }

  .card-td-table-module::before {
    content: attr(data-cell);
    font-weight: bold;
    color: var(--table-header-color);
  }

  .card-tr-table-module {
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .card-tr-table-module:hover {
    background-color: transparent;
  }

  .card-tr-table-module:nth-of-type(2n) {
    background-color: transparent;
  }

  .card-td-table-module {
    word-break: break-all;
  }
}

@media (max-width: 1020px) {
  .card-td-table-module {
    grid-template-columns: 3fr;
    text-align: left !important;
  }

  .card-tr-table-module {
    margin: 10px;
  }

  .card-td-table-module {
    word-break: normal;
  }

  .card-td-table-module[hide-on-mobile="none"] {
    display: none;
  }

  .card-td-table-module::before {
    content: attr(data-cell);
    color: var(--table-header-color);
    font-size: var(--font-15-px);
  }
  .card-dynamic-table-tr {
    background: #242424;
  }
  .card-tr-table-module:nth-of-type(2n-1) .card-td-table-module {
    box-shadow: inset 41px 0px 0px -37px #233239;
    z-index: 100000;
  }

  .card-tr-table-module:nth-of-type(2n) {
    background-color: #242424;
  }

  .card-add-table-border-bottom {
    border-bottom: none;
  }
  .card-caption-table-module,
  .card-th-table-module,
  .card-td-table-module,
  .card-tr-table-module {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .card-td-table-module {
    border-bottom: unset;
    border-top: unset;
  }
  .card-td-table-module:first-child {
    border-left: unset;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .card-tr-table-module:hover .card-td-table-module:first-child {
    box-shadow: unset;
  }

  .card-td-table-module:last-child {
    border-right: unset;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .card-table-module {
    border-spacing: 0px 0px;
  }
}

@media all and (max-width: 1020px) {
  .card-table-container {
    overflow-x: unset;
  }
  .card-table-module {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 0px;
    margin-top: 0px;
  }
}
